import Footer from "../components/Footer"
import config from '../components/config.json'
import Navbar from "../components/Navbar"

export default function Imprint() {
    return (
        <div style={{
            backgroundColor: config.BG
        }}>
            <Navbar/>
            <Main />
            <Footer />
        </div>
    )
}

function Main() {
    return (
        <div style={{
            width: "80vw",
            margin: 0,
            margin: "auto"
        }}>
            <div style={{
                marginBottom: "5vh",
                color: "white"
            }}>
                <h1>Imprint</h1>

                <h2>Information according to &sect; 5 TMG</h2>
                <p>Paul Fischerl&auml;nder<br />
                    Asamstra&szlig;e, 23<br />
                    94486 Osterhofen<br/>
                    Germany
                    </p>

                <h2>Contact</h2>
                <p>E-Mail: paul@fischerlaender.de</p>

                <h2>Editorially responsible</h2>
                <p>Paul Fischerl&auml;nder<br />
                    Asamstra&szlig;e, 23<br />
                    94486 Osterhofen</p>

                <p>Source: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>
        </div>
    )
}