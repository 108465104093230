import { Grid, Card, Text } from '@nextui-org/react';
import config from './config.json'
import { useMediaPredicate } from 'react-media-hook';

const webdevtext = "I've been creating web experiences for a longer time now. About 2 years ago I discovered the abilities of React and I fell in love with it."
const designtext = "For as long as I know, I've been always interested in creative things. I went to a lot of art museums in my live. Started doing serious UI/UX Design 5 years ago. I'm currently trying to learn 3D Design with Blender and Spline"
const cryptotext = "I discorvered crypto like 3 years ago but really became interested in it since the big NFT boom. Learning techniques about it will be crucial for the future generation of developers. Web3 is the future!"

export default function Skills() {
  const biggerThan800 = useMediaPredicate("(min-width: 800px)");
  return (
    <div>
      {biggerThan800 && <div className='skills' style={{
        backgroundColor: config.BG,
        paddingTop: "10vh",
        paddingBottom: "10vh",
        marginBottom: "30vh"
      }}>
        <div style={{
          padding: "3vh",
          display: "flex",
          justifyContent: "space-between"
        }}>
          {Cards("Webdevelopment", webdevtext, "3vw", "30vw")}
          {Cards("Design", designtext, "3vw", "30vw")}
          {Cards("Crypto/NFT", cryptotext, "0", "30vw")}
        </div>
      </div>}

      {!biggerThan800 && <div className='skills' style={{
        backgroundColor: config.BG,
        paddingTop: "10vh",
        paddingBottom: "10vh",
        marginBottom: "5vh"
      }}>
        <div style={{
          padding: "3vh",
          display: "block"
        }}>
          {Cards("Webdevelopment", webdevtext, "0", "100vw", "2vh")}
          {Cards("Design", designtext, "0", "100vw", "2vh")}
          {Cards("Crypto/NFT", cryptotext, "0", "100vw")}
        </div>
      </div>}
    </div>
  )
}

function Cards(title, text, marginR, Cardwidth, marginB) {
  return (
    <Card style={{
      maxWidth: Cardwidth,
      paddingRight: marginR,
      marginBottom: marginB,
      backgroundColor: config.FOOTERBG
    }}>
      <Text style={{
        color: config.ACCENT,
        fontWeight: "500",
        fontSize: "2.5vh",
        marginBottom: "2vh"
      }}>{title}</Text>
      <Text style={{
        color: config.TEXT_COLOR,
        fontWeight: "500",
        fontSize: "2.2vh"
      }}>{text}</Text>
    </Card>
  )
}