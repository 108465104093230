import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@nextui-org/react';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import { BrowserRouter, Routes, Route} from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
  <CssBaseline/>
     <Routes>
      <Route path="/" element={<App />} />
      <Route path="imprint" element={<Imprint/>} />
      <Route path="privacy" element={<Privacy />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
