import { Text } from "@nextui-org/react";
import config from './config.json'
import Twitter from './images/twitter.svg'
import Instagram from './images/instagram.svg'
import { useMediaPredicate } from "react-media-hook";
const textSize = "2.5vh"
const textSizeMobile = "2vh"

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}
const onClickUrl = (url) => {
	return () => openInNewTab(url)
}

export default function Footer() {
	const biggerThan800 = useMediaPredicate("(min-width: 800px)");
	return (
		<div>
			{biggerThan800 && <div style={{
				backgroundColor: config.FOOTERBG,
				paddingBottom: "5vh",
				paddingTop: "5vh",
				bottom: 0
			}}>
				<div className="footer" style={{
					width: "80vw",
					margin: 0,
					margin: "auto",
					display: "flex",
					justifyContent: "space-between"
				}}>
					<div className="logo" style={{
						display: "block"
					}}>
						<a href="/">
							<Text color={config.ACCENT} style={{
								fontSize: textSize,
								fontWeight: "bold",
							}}>paulETH.dev</Text>
						</a>
						<Text color={config.TEXT_COLOR} style={{
							fontSize: textSize,
							fontWeight: "normal",
						}}>Created by <br />Paul Fischerländer in 2022.</Text>
					</div>
					<div className="important" style={{
						display: "block"
					}}>
						<Text color={config.ACCENT} style={{
							fontSize: textSize,
							fontWeight: "bold",
						}}>Important</Text>
						<a href="/imprint">
							<Text color={config.TEXT_COLOR} style={{
								fontSize: textSize,
								fontWeight: "normal"
							}}>Imprint</Text></a>
						<a href="/privacy">
							<Text color={config.TEXT_COLOR} style={{
								fontSize: textSize,
								fontWeight: "normal"
							}}>Privacy Policy</Text></a>
					</div>
					<div className="socialmedia" style={{
						display: "block"
					}}>
						<Text color={config.ACCENT} style={{
							fontSize: textSize,
							fontWeight: "bold",
						}}>Social Media</Text>
						<div style={{
							display: "block",
							justifyContent: "space-evenly",
							marginTop: "2vh"
						}}>
							<a style={{
								marginRight: "2vw"
							}}>
								<img style={{
									width: "4vh"
								}} alt="" src={Twitter} onClick={onClickUrl("https://twitter.com/pauIETH")} />
							</a>
							<a>
								<img style={{
									width: "4vh"
								}} alt="" src={Instagram} onClick={onClickUrl("https://www.instagram.com/ETH_paul/")} />
							</a>
						</div>
					</div>
				</div>
			</div>}

			{!biggerThan800 && <div style={{
				backgroundColor: config.FOOTERBG,
				paddingBottom: "5vh",
				paddingTop: "5vh",
				bottom: 0
			}}>
				<div className="footer" style={{
					width: "80vw",
					margin: 0,
					margin: "auto",
					display: "flex",
					justifyContent: "space-between"
				}}>
					<div className="logo" style={{
						display: "block"
					}}>
						<a href="/">
							<Text color={config.ACCENT} style={{
								fontSize: textSize,
								fontWeight: "bold",
							}}>paulETH.dev</Text>
						</a>
						<Text color={config.TEXT_COLOR} style={{
							fontSize: textSizeMobile,
							fontWeight: "normal",
						}}>Created by <br />Paul Fischerländer in 2022.</Text>
					</div>
					<div className="important" style={{
						display: "block"
					}}>
						<Text color={config.ACCENT} style={{
							fontSize: textSizeMobile,
							fontWeight: "bold",
						}}>Important</Text>
						<a href="/imprint">
							<Text color={config.TEXT_COLOR} style={{
								fontSize: textSizeMobile,
								fontWeight: "normal"
							}}>Imprint</Text></a>
						<a href="/privacy">
							<Text color={config.TEXT_COLOR} style={{
								fontSize: textSizeMobile,
								fontWeight: "normal"
							}}>Privacy Policy</Text></a>
					</div>
					<div className="socialmedia" style={{
						display: "block"
					}}>
						<Text color={config.ACCENT} style={{
							fontSize: textSizeMobile,
							fontWeight: "bold",
						}}>Social Media</Text>
						<div style={{
							display: "block",
							justifyContent: "space-evenly",
							marginTop: "2vh"
						}}>
							<a style={{
								marginBottom: "2vh"
							}}>
								<img style={{
									width: "3vh"
								}} alt="" src={Twitter} onClick={onClickUrl("https://twitter.com/pauIETH")} />
							</a>
							<br />
							<a>
								<img style={{
									width: "3vh"
								}} alt="" src={Instagram} onClick={onClickUrl("https://www.instagram.com/ETH_paul/")} />
							</a>
						</div>
					</div>
				</div>
			</div>}
		</div>
	)
}