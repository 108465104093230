import Logo from './images/logo.svg'
import config from './config.json'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        <div>
            <div style={{
                padding: "2vw"
            }}>
                <Link to="/">
                    <img style={{
                        height: "35px",
                    }} src={Logo}>
                    </img>
                </Link>
            </div>
        </div>
    )
}