import './App.css';
import Skills from './components/Skills';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Footer from './components/Footer';
import config from './components/config.json'

/**
 * 
 * @author Paul Fischerländer aka paulETH in 2022
 * 
 */

function App() {
  document.body.style.backgroundColor= config.BG
  return (
    <div>
      <Hero />
      <Skills />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
