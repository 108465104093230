import { Text } from '@nextui-org/react';
import config from './config.json'
import { useMediaPredicate } from 'react-media-hook';

export default function Hero() {
    const biggerThan800 = useMediaPredicate("(min-width: 800px)");
    return (
        <div>
            {biggerThan800 && <div style={{
                backgroundColor: config.BG,
                marginBottom: "30vh"
            }}>
                <div className='hero' style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: config.BG,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <iframe style={{
                        position: "relative"
                    }} src='https://my.spline.design/iphone13copy-6b48d30d44e97b043e5f4b16fd5b3fe0/' frameborder='0' width='100%' height='100%'></iframe>
                    <Text style={{
                        color: "white",
                        fontSize: "10vh",
                        fontWeight: "bold",
                        marginTop: "-10vh",
                        position: "absolute"
                    }}>paulETH</Text>
                </div>
            </div>}

            {!biggerThan800 && <div style={{
                backgroundColor: config.BG,
                marginBottom: "5vh"
            }}>
                <div className='hero' style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: config.BG,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <iframe style={{
                        position: "relative"
                    }} src='https://my.spline.design/iphone13copy-6b48d30d44e97b043e5f4b16fd5b3fe0/' frameborder='0' width='100%' height='100%'></iframe>
                    <Text style={{
                        color: "white",
                        fontSize: "15vw",
                        fontWeight: "bold",
                        marginTop: "-10vh",
                        position: "absolute"
                    }}>paulETH</Text>
                </div>
            </div>}
        </div>
    )
}