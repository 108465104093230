import { Card, Text } from "@nextui-org/react";
import config from './config.json'
import keeblinklogo from './images/keeblinklogo.svg'
import shinylogo from './images/shinylogo.svg'
import { useMediaPredicate } from "react-media-hook";

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
const onClickUrl = (url) => {
    return () => openInNewTab(url)
}


export default function Projects() {
    const biggerThan800 = useMediaPredicate("(min-width: 800px)");
    return (
        <div>
            {biggerThan800 && <div style={{
                backgroundColor: config.BG,
                marginBottom: "15vh"
            }}>
                <div style={{
                    paddingLeft: "3vh",
                    paddingRight: "3vh"
                }}>
                    <Text style={{
                        color: config.ACCENT,
                        fontSize: "10vh",
                        fontWeight: "bold",
                        marginBottom: "3vh"
                    }}>My Projects</Text>
                    {ProjectNoImage("KeebLink", "10vh",keeblinklogo, "10vh", "20vw", "2vh", "-10vh", "https://keeblink.pages.dev", "50vh", "100vw")}
                    {ProjectNoImage("Shiny.to", "10vh", shinylogo, "10vh", "20vw",  "2vh", "-10vh","#", "50vh", "100vw")}
                </div>
            </div>}

            {!biggerThan800 && <div style={{
                backgroundColor: config.BG,
                marginBottom: "10vh"
            }}>
                <div style={{
                    paddingLeft: "3vh",
                    paddingRight: "3vh"
                }}>
                    <Text style={{
                        color: config.ACCENT,
                        fontSize: "6vh",
                        fontWeight: "bold",
                        marginBottom: "3vh"
                    }}>My Projects</Text>
                    {ProjectNoImage("KeebLink", "4vh", keeblinklogo, "4.5vh", "25vw", "2vh", "-3.7vh", "https://keeblink.pages.dev", "20vh", "100vw")}
                    {ProjectNoImage("Shiny.to", "4vh", shinylogo, "4.5vh", "25vw", "2vh", "-3.7vh", "#", "20vh", "100vw")}
                </div>
            </div>}
        </div>
    )
}

function ProjectNoImage(title, titleSize, logoimg, logowidth, marginR, marginBT, marginTP, url, CardHeight, CardWidth) {
    return (
        <Card clickable onClick={onClickUrl(url)} style={{
            backgroundColor: config.FOOTERBG,
            marginBottom: marginBT,
            overflow: "hidden"
        }}>
            <div style={{
                display: "flex",
                overflow: "hidden",
                height: CardHeight,
                maxWidth: CardWidth,
                padding: "2vw",
                justifyContent: "space-evenly"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: marginR,
                    overflow: "hidden"
                }}>
                    <div style={{
                        marginRight: "2vw",
                        overflow: "hidden"
                    }}>
                        <img style={{
                            width: logowidth
                        }}
                            alt=""
                            src={logoimg} />
                    </div>
                    <div style={{
                        overflow: "hidden"
                    }}>
                        <Text style={{
                            color: "white",
                            fontSize: titleSize,
                            fontWeight: "bold",
                            marginTop: marginTP,
                            position: "absolute"
                        }}>
                            {title}
                        </Text>
                    </div>
                </div>
            </div>
        </Card>
    )
}